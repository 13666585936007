.dialog {
  &__overlay {
    background-color: rgb(0 0 0 / 0.4);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &__content {
    background-color: white;
    border-radius: 6px;
    box-shadow:
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    // scrollbar is usally 15px wide, soe remove half of it to center dialog properly
    left: calc(50% - 15px / 2);
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 800px;
    max-height: 70vh;
    padding: 2rem;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    overflow-y: auto;

    &:focus {
      outline: none;
    }
  }

  &__title {
    margin-block-end: 1.25rem;
    // font-size: var(--fs-800);
  }

  &__description {
    margin-block: 0.75rem 1.25rem;
    line-height: 1.5;
  }

  &__close {
    cursor: pointer;
    border-radius: 100%;
    font-size: 1.5rem;
    height: 32px;
    width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;

    &:hover {
      background-color: var(--clr-light-grey);
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--clr-dark-grey);
    }
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
