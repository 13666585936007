@use '../abstracts/mixins' as *;

.contacts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacer-sm);
  justify-items: center;
  width: auto !important;

  &__person {
    width: 250px;
  }

  &__image {
    margin-block-end: 1rem;
    object-fit: cover;
  }
}

@include mq(small) {
  .contacts {
    gap: var(--spacer-md);
  }
}
