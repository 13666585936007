@use '../abstracts/mixins' as *;

.course-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  justify-items: center;
  isolation: isolate;
}

@include mq(small) {
  .course-header {
    justify-items: initial;
  }
}
