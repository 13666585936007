@use '../abstracts/mixins' as *;
@use '../utilities/container';

.hero {
  // @extend .container !optional;
  margin-block-start: 0;

  &__image {
    height: 200px;
    width: 100%;
    object-fit: cover;
    max-width: 1920px;
    margin-inline: auto;
  }

  &__title {
    margin-block: 2.5rem;
    hyphens: auto;
  }

  &__title--has-type {
    margin-block-start: 0.75rem;
    margin-block-end: 2.5rem;
  }

  &__product-type {
    margin-block-start: 2.5rem;
    margin-bottom: 0;
    font-weight: bold;
    font-size: var(--fs-600);
  }
}

@include mq(small) {
  .hero {
    &__image {
      height: 300px;
    }
  }
}

@include mq(medium) {
  .hero {
    &__image {
      height: 500px;
    }
  }
}
