.event-registration-form {
  display: grid;
  gap: 1rem;

  &__title {
    margin-block-start: 0;
    margin-block-end: 1.25rem;
    font-size: var(--fs-700);
    font-weight: 400;
  }

  &__description {
    margin-block-end: 2rem;
  }

  &__description-title {
    font-weight: 600;
  }

  &__row {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .checkbox__label {
    font-size: var(--fs-400);
  }

  .button[type='submit'] {
    margin-block-start: 1.25rem;
  }
}
