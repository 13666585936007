.image {
  padding: 0;
  margin-inline: auto;
  width: 100%;

  &__element {
    width: 100%;
    height: auto;
  }

  &--full-width {
    width: 100%;
    width: auto;
  }
}
