.course-information {
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1.25rem 1.875rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    position: relative;
    padding: 0.625rem 2rem;
    background-color: var(--clr-light-grey);
    border-bottom: 2px solid var(--clr-primary);

    h3 {
      font-size: var(--fs-300);
    }

    p {
      color: var(--clr-secondary);
      font-weight: 700;
    }
  }
}
