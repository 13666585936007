@use 'sass:list';
@use '../abstracts' as *;

:root {
  --spacer-lg: 5rem;
  --spacer-md: 3rem;
  --spacer-sm: 2rem;

  --border-radius: 0.5rem;
  --list-spacing: 0.5rem;

  @include mq(medium) {
    --section-spacing: 7rem;
    --spacer-lg: 5rem;
  }

  @each $color, $value in $colors {
    --clr-#{""+$color}: #{'' + $value};
  }

  @each $size, $property in $type-scale {
    --fs-#{$size}: #{fluid(list.nth($property, 1), list.nth($property, 2))};
  }
}
