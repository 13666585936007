.richtext {
  h2 {
    margin-block-start: 0;
    margin-block-end: 1.25rem;
  }

  h3 {
    margin-block-start: 0;
    margin-block-end: 1.25rem;
  }

  p + h2 {
    margin-block-start: 2.5rem;
  }

  h2 + h3,
  p + h3 {
    margin-block-start: 2.5rem;
  }

  ul {
    padding: 0;
    padding-inline-start: 1.25rem;
  }

  li:not(:first-child) {
    margin-block-start: 1.25rem;
  }

  ul ul li:not(:first-child),
  ul ul li:first-child {
    margin-block-start: 0.25rem;
  }

  li::marker {
    color: var(--clr-primary);
  }

  p + p {
    margin-block-start: 1.25rem;
  }

  a {
    color: var(--clr-secondary);
  }

  img.richtext-image {
    object-fit: contain;

    &.left {
      float: left;
      margin-inline-end: 1rem;
      margin-block: 1rem;
    }

    &.right {
      float: right;
      margin-inline-start: 1rem;
      margin-block: 1rem;
    }
  }

  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
