.tooltip {
  position: absolute;
  top: 10px;
  right: 10px;

  &__trigger {
    appearance: none;
    border: none;
    background-color: transparent;
    padding: 0;
    color: var(--clr-black);
    font-size: var(--fs-300);

    &:focus-visible {
      outline: 4px solid currentColor;
    }
  }

  &__content {
    border-radius: 4px;
    padding: 10px 15px;
    line-height: 1;
    color: var(--clr-secondary);
    background-color: var(--clr-white);
    box-shadow:
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    // max-width: 100vw;

    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    animation-fill-mode: forwards;
    will-change: transform opacity;

    &[data-state='delayed-open'] {
      &[data-side='top'] {
        animation-name: slideDownAndFade;
      }
      &[data-side='right'] {
        animation-name: slideLeftAndFade;
      }
      &[data-side='bottom'] {
        animation-name: slideUpAndFade;
      }
      &[data-side='left'] {
        animation-name: slideRightAndFade;
      }
    }
  }

  &__arrow {
    fill: var(--clr-white);
  }
}

@keyframes slideUpAndFade {
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  0% {
    opacity: 0;
    transform: translateX(2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
