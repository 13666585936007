.button {
  --bg-color: var(--clr-white);
  --border-color: var(--clr-secondary);
  --color: var(--clr-secondary);

  color: var(--color);
  background-color: var(--bg-color);
  border: 2px solid var(--border-color);

  padding: 0.5em 2em;
  border-radius: var(--border-radius, 15px);

  cursor: pointer;
  font-weight: 600;
  transition: box-shadow ease 0.2s;

  &:focus-visible {
    outline: 2px solid var(--border-color);
    outline-offset: 2px;
  }

  &:hover {
    //box-shadow: inset 0px 0 6px 2px rgba(0, 0, 0, 0.3);
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &--primary {
    --border-color: var(--clr-primary);
    --bg-color: var(--clr-primary);
    --color: var(--clr-white);

    &:hover {
      color: var(--clr-white);
    }
  }

  &--secondary {
    --bg-color: var(--clr-secondary);
    --border-color: var(--clr-secondary);
    --color: var(--clr-white);
  }
}
