.radio-group {
  &__root {
    display: flex;
    flex-direction: column;
    gap: 1em;

    &--row {
      flex-direction: row;
      gap: 2rem;
    }

    &--centered {
      justify-content: center;
      text-align: center;
    }
  }

  &__item-container {
    display: flex;
    align-items: center;
  }

  &__item {
    all: unset;
    font-size: 1rem;
    background-color: transparent;
    border: 2px solid var(--clr-dark-grey);
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
      outline: 1px solid var(--clr-dark-grey);
    }

    &:focus-visible {
      outline: 2px solid var(--clr-dark-grey);
    }
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--clr-dark-grey);
    }
  }

  &__label {
    color: var(--clr-dark-grey);
    padding: 0 0 0 0.5em;
    font-size: clamp(1.125rem, 4vw, 1.5rem);
    line-height: 1;
    cursor: pointer;
    font-weight: 300;
  }

  &__error {
    color: red;
    border-radius: 15px;
    background: var(--clr-dark-grey);
    font-size: clamp(1rem, 4vw, 1.25rem);
    padding: 1em;
    display: flex;
    align-items: center;
    gap: 1em;
    margin: 1em 0;

    span {
      margin: 0.35em 0 0;
    }
  }
}
