@use '../abstracts/mixins' as *;

.cta {
  display: grid;
  gap: 1em;
  padding: 1em;
  background-color: var(--clr-light-grey);
  place-items: center;

  &__image {
    width: 100%;
  }

  &__link {
    text-align: center;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text {
    hyphens: auto;
  }
}

@include mq(medium) {
  .cta {
    padding: 2em;
    gap: 3em 1em;
    place-items: center;
    grid-template-areas: 'text image' 'link link';
    grid-template-columns: repeat(2, 1fr);

    &__image {
      grid-area: image;
      width: 100%;
    }

    &__link {
      grid-area: link;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &__text {
      grid-area: text;
    }
  }
}
