.links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.875rem;
  text-align: center;
  padding: 0;

  &__tile {
    border: 3px solid var(--clr-primary);
    padding: 0.5em 2em 0.9em;
    border-radius: var(--border-radius, 15px);
    text-decoration: none;
    transition: box-shadow ease 0.1s;
    &:hover {
      text-decoration: none;
      box-shadow: var(--box-shadow);
    }
    .title {
      font-size: 1.875rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  .icon,
  .icon svg {
    width: auto;
    height: 4.875rem;
  }
}
