.rating {
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  justify-items: center;
  grid-template-rows: repeat(2, auto);
  width: fit-content;

  &__stars {
    display: inline-flex;
    align-items: center;
  }

  &__star {
    display: inline-flex;
    width: 40px;
    height: 40px;
    position: relative;

    & > svg {
      position: absolute;
      inset: 0;
    }
  }
}
