@use '../abstracts/mixins' as *;

.nav {
  display: flex;
  gap: 1rem;

  @include mq(medium) {
    margin-right: 1.125rem;
  }

  &__contact {
    margin-inline-start: auto;
    .streamfield {
      margin: 0;
      width: 100%;
    }

    & .reservation__link {
      padding: 0.625em 2em;
    }

    &.popover {
      position: initial;
      display: inline-flex;
      gap: 0.5rem;
      align-items: center;
      text-decoration: none;
    }

    &.popover__trigger {
      background-color: var(--clr-primary);
      border-radius: 0.625rem;
      padding: 0.75rem 1rem;
      font-size: var(--fs-400);
      font-weight: 700;
      color: var(--clr-white);
    }

    &.popover__content {
      z-index: 2;
      overflow: hidden;
      padding: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    background-color: var(--clr-secondary);
    color: white;
    position: absolute;
    right: -1rem;
    margin: 0;
    padding: 1rem;
    top: 6rem;
    list-style: none;
    width: 100%;
    height: calc(100vh - 6rem);
    overflow-y: scroll;
    max-width: 516px;
    translate: 100% 0;
    transition: translate 300ms;

    @include mq(small) {
      padding: 1.25rem 2rem 1.75rem 2rem;
    }

    @include mq(medium) {
      padding: 1.5rem 2rem 2rem 3rem;
    }
  }

  &__content-headline {
    font-weight: bold;
    font-size: var(--fs-700);
    padding: 0.5rem 0;
  }

  &__content-list {
    list-style: none;
    padding: 0;
    padding-inline-start: 1.5rem;
    margin: 0;
  }

  &__list-item,
  &__sub-list-item {
    color: var(--clr-white);
    font-size: var(--fs-600);
    padding: 1rem 0;

    @include mq(small) {
      font-size: var(--fs-700);
      padding: 0.5rem 0;
    }
  }

  &__link {
    color: var(--clr-white);
  }

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    width: 100%;
    color: white;
    border: none;
    padding: 0;
  }

  .hamburger[aria-expanded='true'] ~ &__list {
    translate: 0 0;
  }
}
