@use '../abstracts' as *;

@each $color, $value in $colors {
  .text-#{'' + $color} {
    color: var(--clr-#{'' + $color}) !important;
  }

  .bg-#{'' + $color} {
    background-color: var(--clr-#{'' + $color}) !important;
  }
}

.primary {
  --clr: var(--clr-primary);
}

.secondary {
  --clr: var(--clr-secondary);
}
