@use '../abstracts/mixins' as *;

.tabs {
  --spacer: 1.5626rem;
  padding-left: 0;
  padding-right: 0;

  &__list {
    margin-bottom: var(--spacer);
    display: grid;
    gap: var(--spacer);

    @include mq(medium) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

  &__trigger {
    cursor: pointer;
    position: relative;
    border: 3px solid var(--clr-primary);
    background-color: var(--clr-white);
    border-radius: var(--border-radius, 15px);
    transition: var(--transition-all);
    color: var(--clr-primary);
    padding: 2.5rem;

    &[data-state='active'] {
      box-shadow: var(--box-shadow);

      &::after {
        content: '';
        position: absolute;
        bottom: -0.8125rem;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.625rem 1rem 0 1rem;
        border-color: var(--clr-primary) transparent transparent transparent;
      }
    }
  }

  &__trigger-icon {
    margin-top: 1.875rem;
    svg {
      max-height: 4.875rem;
    }
  }

  &__content {
    @include mq(medium) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacer);
    }
  }

  &__content-left {
    grid-column: 1 / span 1;
    display: flex;
    flex-direction: column;
    gap: var(--spacer);

    a {
      display: flex;
      align-items: center;
      gap: var(--spacer);
      border: 3px solid var(--clr-primary);
      border-radius: var(--border-radius, 15px);
      text-decoration: none;
      font-size: 1.5626rem;
      padding: 0.75rem 1.25rem;
    }

    .icon {
      display: flex;
      align-items: center;

      svg {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }

  &__content-right {
    grid-column: 2 / span 2;
  }
}
