@use '../utilities/container';

.footer {
  background-color: var(--clr-secondary);
  color: var(--clr-white);
  padding-block: 2.5rem;

  &__text {
    text-align: center;
    text-wrap: balance;
    margin-block-end: 2rem;
  }

  &__links {
    margin-block: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    list-style: none;
    padding: 0;
  }

  &__link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: var(--fs-500);
    font-weight: 700;
    color: var(--clr-white);

    &--tel {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        height: 1.5em;
      }

      svg path {
        stroke: white;
      }
    }
  }

  &__copyright {
    text-align: center;
    margin-block-start: 2.5rem;
  }
}
