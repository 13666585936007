@use '../abstracts/mixins' as *;

.eye-catcher {
  display: flex;
  justify-content: flex-end;

  &__link:focus-visible:focus-within &__text {
    outline: 4px solid var(--clr-secondary);
    outline-offset: 4px;
  }

  & b,
  & strong {
    font-size: 2.25rem;
    line-height: 1;
  }

  &__text {
    width: 130px;
    height: 130px;
    background: var(--clr-secondary);
    color: var(--clr-white);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 100vmax;
    font-size: var(--fs-400);

    & p {
      margin-top: -0.75rem;
    }
  }
}

@include mq(small) {
  .eye-catcher {
    --spacer: 0;

    &__text {
      display: inline-flex;
      z-index: 1;
      width: 150px;
      font-size: var(--fs-600);
      height: 150px;
    }
  }
}
