@use '../abstracts/mixins' as *;

.container-section {
  background-color: var(--clr-grey-background);
  padding: 2.5rem 0rem;

  & > .container {
    width: min(100% - 2rem, 800px);
    margin: 0 auto 0;
    padding: 0;
  }

  &--small {
    &.container-section.streamfield {
      width: min(100%, 800px);
      margin: 0 auto var(--spacer);
    }
  }

  &.streamfield {
    width: 100%;
  }
}

@include mq(small) {
  .container-section {
    padding: 2.5rem 1rem;

    &--small {
      width: min(100% - 2rem, 800px);
    }
  }
}
