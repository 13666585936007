.input {
  display: flex;
  flex-direction: column;

  &__element {
    border: none;
    font-size: var(--fs-450);
    padding: 0.5rem;
    border: 2px solid var(--clr-dark-grey);
    border-radius: 0;

    &:focus-visible {
      outline: 2px solid var(--clr-dark-grey);
    }

    &::-webkit-calendar-picker-indicator {
      color: var(--clr-primary);
    }
  }

  &--error &__element {
    border-color: firebrick;
  }

  &--error .label {
    color: firebrick;
  }

  &__error-message {
    color: firebrick;
  }
}
