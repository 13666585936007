@use './label';
@use '../../abstracts/mixins' as *;

.fieldset {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;
  grid-column: 1 / -1;

  &__legend {
    @extend .label !optional;
    margin: 0 0 1em 0;
    font-size: var(--fs-700);
  }

  &--as-label &__legend {
    color: hsl(var(--clr-neutral-800));
    font-size: clamp(1.25rem, 4vw, 1.5rem);
    font-weight: 600;
    display: inline-block;
    padding: 0 1rem;
    margin-block-end: 0.5rem;
    text-align: left;
  }

  &--centered &__legend {
    text-align: center;
  }
}

.fieldset__fields-container {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
  }
}
