@use '../../abstracts/mixins' as *;

.form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;

  &__submit {
    width: max-content;
    margin-inline: auto;
  }

  &__intro {
    font-size: var(--fs-600);
    font-weight: 700;
  }
}

.form__fields {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
}

.form__field--full-width {
  grid-column: 1 / -1;

  @include mq(small) {
    max-width: calc(400px - 1rem);

    &.form__field--singleline,
    &.form__field--textarea,
    &.form__field--checkbox {
      max-width: 100%;
    }
  }
}
