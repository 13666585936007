.products {
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    list-style: none;
    padding: 0;
    gap: 1rem;
  }

  &__list-item {
    padding: 1rem;
    background-color: var(--clr-light-grey);
    border-left: 4px solid var(--clr-primary);
  }

  &__type {
    font-size: var(--fs-400);
    font-weight: 700;
  }

  &__link {
    font-family: var(--font-exo);
    font-size: var(--fs-500);
    font-weight: 600;
    color: var(--clr-secondary);
  }
}
