@use '../abstracts/mixins' as *;

.target-group {
  &__header {
    display: block;
    margin: 0;
  }

  &__image {
    position: relative;
    height: 300px;
    overflow: hidden;
  }

  &__title {
    margin: 0;
    margin-block: 0.75rem;
    color: var(--clr-secondary);
    margin-block-start: 2rem;
  }

  &__list {
    margin-block-start: 2rem;
    margin-block-end: 0;
    padding: 0;
    display: grid;
    gap: 1rem;
    list-style: none;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fs-400);
    font-weight: 600;
    color: var(--clr-secondary);
    background-color: var(--clr-light-grey);
    padding: 1rem;
    border-radius: 10px;
    line-height: 1.2;

    svg {
      flex-shrink: 0;
    }
  }
}
