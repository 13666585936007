@use '../abstracts/mixins' as *;

.table {
  hyphens: auto;
  // Blocktitle
  &__title {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  &__icon {
    text-align: center;
  }

  &__element,
  &__head,
  &__data {
    border: 1px solid var(--clr-primary);
    border-collapse: collapse;
  }

  &__caption,
  &__head,
  &__data {
    text-align: left;
  }

  &__head,
  &__data {
    padding: 0.25em 0.5em;

    @include mq(medium) {
      padding: 0.125em 0.5em;
    }
  }

  &__element {
    width: 100%;
  }

  &__caption {
    color: var(--clr-primary);
    font-size: var(--fs-500);
    font-weight: 600;
    margin: 0.8em 0;
  }

  &__header {
  }

  &__body {
  }

  &__row:nth-child(odd) {
  }

  &__head {
    background-color: var(--clr-primary);
    color: var(--clr-white);
  }

  &__head,
  &__data {
    font-size: 1.125rem;

    @include mq(medium) {
      font-size: var(--fs-500);
    }
  }

  &__data {
    color: var(--clr-primary);
  }
}

.streamfield.table .bg-wrapper {
  background: var(--clr-lighter-grey);
  padding: 2.5rem 1rem;
  border-radius: var(--border-radius);

  @include mq(medium) {
    padding: 2.5rem;
  }
}

.container-field--light-grey,
.container-field--light-blue,
.container-field--gradient-light-blue-dark-blue,
.container-field--gradient-light-blue-white {
  .streamfield.table .bg-wrapper {
    background: var(--clr-white);
    border-radius: var(--border-radius);
    padding: 1rem;
    margin-inline: 0.5rem;

    @include mq(medium) {
      padding: 2.5rem;
      margin-inline: 0;
    }
  }
}
