.content-grid {
  --_content-width: var(--content-width, 800px);
  --_breakout-width: var(--breakout-width, 1440px);
  --_padding-inline: var(--inner-padding, 1rem);
  --_grid-spacing: var(--grid-spacing, var(--spacing-large));
  --_breakout-size: calc((var(--_breakout-width) - var(--_content-width)) / 2);
  --_content-half: calc(min(100% - var(--_padding-inline) * 2, var(--_content-width)) / 2);

  margin-inline: auto;
  max-width: 1920px;

  &,
  .full-width {
    display: grid;
    align-content: start;
    // grid-auto-flow: dense;
    gap: var(--_grid-spacing) 0;
    width: 100%;
    // max-width: 1920px;
    grid-template-columns: [full-width-start] 1fr [breakout-start] minmax(0, var(--_breakout-size)) [content-start] var(
        --_content-half
      ) [content-center] var(--_content-half) [content-end] minmax(0, var(--_breakout-size)) [breakout-end] 1fr [full-width-end];
  }

  & > :not(.breakout, .full-width),
  .full-width > :not(.breakout, .full-width) {
    grid-column: content;
  }

  .full-width > .full-width,
  & > .full-width {
    grid-column: full-width;
  }

  .full-width > .breakout,
  & > .breakout {
    grid-column: breakout;
  }

  .full-width > .breakout-left,
  & > .breakout-left {
    grid-column: breakout-start / content-center;
  }

  .full-width > .breakout-right,
  & > .breakout-right {
    grid-column: content-center / breakout-end;
  }

  .full-width > .content-left,
  & > .content-left {
    grid-column: content-start / content-center;
  }

  .full-width > .content-right,
  & > .content-right {
    grid-column: content-center / content-end;
  }

  .full-width > .full-width-left,
  & > .full-width-left {
    grid-column: full-width-start / content-center;
  }

  .full-width > .full-width-right,
  & > .full-width-right {
    grid-column: content-center / full-width-end;
  }
}
