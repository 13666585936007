@use '../../abstracts/mixins' as *;

.select {
  display: flex;
  flex-direction: column;
  isolation: isolate;
  z-index: 1;

  &__trigger {
    color: var(--clr-secondary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--clr-secondary);
    background: var(--clr-white);
    font-size: var(--fs-450);
    overflow: hidden;
    padding: 0;
    padding-inline-start: 0.5rem;

    & > *:first-child {
      margin-inline-end: auto;
    }

    &:focus-visible {
      outline: 2px solid var(--clr-secondary);
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    background-color: var(--clr-secondary);

    @include mq(small) {
      width: 4rem;
    }
  }

  &__icon svg {
    transition: transform 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__trigger[data-state='open'] &__icon svg {
    transform: rotate(180deg);
  }

  &__viewport {
    width: 100%;
  }

  &__content {
    overflow: hidden;
    background-color: var(--clr-white);
    border: 2px solid var(--clr-secondary);
    margin-left: 1rem;
    margin-right: 1rem;
    z-index: 1;

    @include mq(small) {
      margin: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem 0.5rem 2rem;
    cursor: pointer;
    font-size: var(--fs-450);

    &[data-disabled] {
      pointer-events: none;
      cursor: not-allowed;

      & > * {
        color: grey;
      }
    }

    &[data-highlighted] {
      outline-color: transparent;
      background-color: var(--clr-secondary);
      color: var(--clr-white);
    }
  }

  &__item-indicator {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    left: 0;
  }

  &__item[data-highlighted] &__item-indicator svg * {
    fill: white !important;
  }

  &__scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-dark-grey);
    cursor: default;
  }

  // grey modifier code
  &--grey {
    --border-radius: 0;
    --clr-secondary: var(--clr-dark-grey);
  }

  &--grey &__icon {
    background-color: transparent;

    svg * {
      fill: var(--clr-dark-grey) !important;
    }
  }

  &__content--grey {
    --border-radius: 0;
    border-color: var(--clr-dark-grey);
  }

  &__content--grey &__item[data-highlighted] {
    outline-color: transparent;
    background-color: var(--clr-dark-grey) !important;
    color: var(--clr-white);
  }

  &__content--rounded {
    border-radius: var(--border-radius);
  }

  &--rounded &__trigger,
  &__content--rounded {
    border-radius: var(--border-radius);
  }
}
