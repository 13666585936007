.icon {
  width: 120px;
  height: 120px;
  background-color: white;
  padding: 1.5rem;
  border-radius: 100%;
  display: grid;
  place-items: center;
  margin-inline: auto;
  margin-block-end: 1.25rem;

  svg {
    stroke: var(--clr-primary);
    stroke-width: 3;
  }
}
