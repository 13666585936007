.quote {
  font-weight: 600;

  blockquote {
    font-size: var(--fs-600);
    quotes: '„' '”';
  }
  figcaption {
    margin-top: 0.625rem;
    font-size: 1.5rem;
    color: var(--clr-dark-grey);
  }
}
