.columns {
  --grid-gap: 1rem;

  padding-inline: 0;

  & > * + * {
    margin: var(--grid-gap) 0 0;
  }

  & .streamfield,
  & .container,
  & .image {
    --spacer: 1rem;
  }

  &__grid {
    display: grid;
    gap: var(--grid-gap);

    &[data-type='column_2_1'] {
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);

      & > :first-child {
        background-color: lightblue;
      }

      & > :last-child {
        background-color: lightgreen;
      }
    }

    &[data-type='column_1_1'] {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

      & > :first-child {
        background-color: lightblue;
      }

      & > :last-child {
        background-color: lightgreen;
      }
    }
  }
}
