.checkbox {
  display: flex;

  &--baseline {
    align-items: flex-start;
  }

  &__root {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background-color: transparent;
    width: 1em;
    height: 1em;
    margin-top: 0.25rem;
    border-radius: 4px;
    border: 2px solid var(--clr-dark-grey);
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
      //background-color: rgba(c.$purple, 0.2);
    }

    &:focus-visible {
      outline: 2px solid var(--clr-dark-grey);
      outline-offset: 2px;
    }
  }

  &__indicator {
    display: inline-flex;
    align-items: center;
    height: 1em;
  }

  &__label {
    padding: 0 0 0 0.5em;
    line-height: 1.4;
    cursor: pointer;
    user-select: none;
    font-weight: 300;

    a {
      color: inherit;
      font-weight: 600;
    }
  }

  &--error &__root {
    border-color: firebrick;
  }

  &__error-message {
    color: firebrick;
  }
}
