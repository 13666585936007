.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.screenreader-text {
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  top: auto;

  &:focus {
    display: inline-block;
    height: auto;
    width: auto;
    position: static;
    margin: auto;
  }
}
